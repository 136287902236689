import Footer from './components/Footer';
import Header from './components/Header';
import Particles from './components/Particles';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';



function App() {

  return (
    <>
      <Router>
        <Particles />
        <Header />

        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* 404 */}
          <Route path="*" element={<Home />} />
        </Routes>


        <Footer />

      </Router >
    </>
  );
}

export default App;
