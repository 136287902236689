import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/fontawesome-free-regular";

const apiURL = process.env.REACT_APP_API_URL;
const SocialMedia = (props) => {
  const [socialMediaData, setSocialMediaData] = useState([]);

  useEffect(() => {
    axios
      .get(`${apiURL}social-media`)
      .then((res) => {
        setSocialMediaData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  library.add(faGithub);
  return (
    <ul>
      {socialMediaData &&
        socialMediaData.map((item) => (
          <li key={item.id}>
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              className="d-block"
            >
              {item.url.includes("github") ? (
                <FontAwesomeIcon icon={faGithub} />
              ) : (
                <FontAwesomeIcon icon={faLinkedin} />
              )}
              {props.listType === "full" && <span>{item.name}</span>}
            </a>
          </li>
        ))}
      {props.listType === "full" && (
        <li>
          <a
            href="mailto:t.rapesovski@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
            className="d-block"
          >
            <FontAwesomeIcon icon={faEnvelope} />
            <span>t.rapesovski@gmail.com</span>
          </a>
        </li>
      )}
    </ul>
  );
};

export default SocialMedia;
