import React, { useState } from "react";
import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;

const ContactForm = () => {
  const [formStatus, setFormStatus] = useState("Send");
  const [formLoading, setFormLoading] = useState(false);
  const [formMessage, setFormMessage] = useState("");

  const resetFormStatus = () => {
    setTimeout(() => {
      setFormStatus("Send");
    }, 3000);
  };

  const submitForm = async (formData, e) => {
    try {
      const response = await axios.post(`${apiURL}send-email`, formData);
      if (response.data.message === "Email sent successfully") {
        setFormStatus("Sent");
        setFormMessage("Message sent successfully.");
        e.target.reset(); // Reset the form
      } else {
        setFormStatus("Failed");
        setFormMessage("Failed to send message.");
      }
    } catch (error) {
      setFormStatus("Failed");
      setFormMessage("Failed to send message.");
    } finally {
      resetFormStatus();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setFormStatus("Submitting...");
    setFormLoading(true);

    const { name, email, message } = e.target.elements;
    let formData = {
      name: name.value,
      email: email.value,
      message: message.value,
    };

    submitForm(formData, e); // Pass the event to the submitForm function
  };

  return (
    <div className="form-container">
      <h3>Contact Now</h3>
      <h2>Let’s Work Together.</h2>
      <p>If you’ve got a project in mind, let’s talk!</p>
      <form
        onSubmit={onSubmit}
        className={formLoading ? "loading" : ""}
        style={{ cursor: formLoading ? "not-allowed" : "pointer" }}
      >
        <div className="form-control">
          <input type="text" id="name" required placeholder="Full Name" />
        </div>
        <div className="form-control">
          <input type="email" id="email" required placeholder="Email" />
        </div>
        <div className="form-control">
          <textarea id="message" required placeholder="Message.." rows={8} />
        </div>
        <div className="form-control">
          <button
            className="btn btn-primary"
            type="submit"
            disabled={formLoading}
          >
            <span>{formStatus}</span>
          </button>
        </div>
        {formMessage && (
          <div
            className="form-message"
            style={{
              color: formStatus === "Failed" ? "red" : "green",
              fontSize: "3rem",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            {formMessage}
          </div>
        )}
      </form>
    </div>
  );
};

export default ContactForm;
