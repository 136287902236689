import React, { useState, useEffect } from "react";
import axios from "axios";
import Btn from "./Button";
import gsap from "gsap";

const fetchHeroData = async () => {
  const apiURL = process.env.REACT_APP_API_URL;
  try {
    const response = await axios.get(`${apiURL}hero`);
    return response.data;
  } catch (err) {
    console.error("Failed to fetch hero data:", err);
    throw err; // Re-throw to handle it in the calling context if needed
  }
};

const useHeroAnimation = () => {
  useEffect(() => {
    gsap.to("#hero img", {
      duration: 3,
      y: 30,
      ease: "none",
      repeat: -1,
      yoyo: true,
    });
  }, []);
};

const Hero = () => {
  const [heroData, setHeroData] = useState({});

  useHeroAnimation(); // Custom hook for GSAP animation

  useEffect(() => {
    const getHeroData = async () => {
      try {
        const data = await fetchHeroData();
        setHeroData(data);
      } catch (error) {
        // Handle or display error to the user
      }
    };
    getHeroData();
  }, []);

  return (
    <div id="hero">
      <div className="row">
        <div className="col">
          <h1 dangerouslySetInnerHTML={{ __html: heroData.heroTitle }} />
          <p>{heroData.heroContent}</p>
          <Btn btnType="primary" linkTo={heroData.heroBtnUrl}>
            {heroData.heroBtnText}
          </Btn>
        </div>
        <div className="img-wrapper">
          <img
            src={heroData.heroImg}
            alt={heroData.heroTitle}
            className="full-size-img full-size-img-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
