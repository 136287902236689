import React, { useEffect, useLayoutEffect, useState } from "react";
import "../style/fonts/hacked/hacked.scss";

const Preloader = () => {
  const [theName, setTheName] = useState("");
  useEffect(() => {
    setTheName("My name is Tomas");
  }, []);
  return (
    <div id="preloader">
      <div className="logo-wrapper">
        <p>{theName}</p>
      </div>
    </div>
  );
};

export default Preloader;
