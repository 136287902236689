import React, { useEffect, useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import DOMPurify from "dompurify";

const ProjectModal = ({ closeModal, data }) => {
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    // Check if `data` is an array and has at least one item
    if (data && data.length > 0) {
      setModalData(data[0]); // Set the first item as modalData
    } else {
      setModalData(null); // Reset modalData if no data is available
    }
  }, [data]);

  useEffect(() => {
    const closeModalOnEsc = (e) => {
      if (e.key === "Escape") {
        closeModal();
      }
    };

    document.addEventListener("keydown", closeModalOnEsc);

    // Focus management for accessibility
    const modalElement = document.getElementById("project-modal");
    if (modalElement) {
      modalElement.focus();
    }

    // Cleanup
    return () => {
      document.removeEventListener("keydown", closeModalOnEsc);
    };
  }, [closeModal]);

  const closeModalOnOutsideClick = useCallback(
    (e) => {
      if (e.target.id === "project-modal") {
        closeModal();
      }
    },
    [closeModal]
  );

  // Render null if modalData is not available to prevent access errors
  if (!modalData) {
    return null;
  }

  return (
    <div
      id="project-modal"
      className="project-modal active"
      onClick={closeModalOnOutsideClick}
      tabIndex="-1"
      role="dialog"
      aria-modal="true"
      aria-labelledby="project-modal-title"
      aria-describedby="project-modal-description"
    >
      <div className="modal-content">
        <span className="close close-modal" onClick={closeModal}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <div className="row">
          <div className="col">
            <h1 id="project-modal-title">{modalData.title}</h1>
            <div
              className="modal-description"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(modalData.description || ""),
              }}
            ></div>
          </div>
          <div className="col col-video">
            {modalData.the_video && (
              <video
                controls
                autoPlay
                muted
                loop
                poster={modalData.video_poster.large}
                className="modal-video"
              >
                <source src={modalData.the_video.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectModal;
