import React from "react";

const ProjectCard = ({ item, handleModal }) => {
  /*  'id' => get_the_ID(),
            'title' => get_the_title(),
            'image' => get_the_post_thumbnail_url(get_the_ID(), 'large'),
            'project_category' => get_the_terms(get_the_ID(), 'project_category'),
            'the_video' => get_field('project_video', get_the_ID()) ? get_field('project_video', get_the_ID()) : false,
            'video_poster' => get_field('video_poster', get_the_ID()) ? get_field('video_poster', get_the_ID()) : false,
            'description' => get_field('description', get_the_ID()) ? get_field('description', get_the_ID()) : false,
            'short_description' => get_field('short_description', get_the_ID()) ? get_field('short_description', get_the_ID()) : false, 
            */
  const {
    image,
    project_category,
    short_description,
    title,
    id,
    the_video,
    video_poster,
    description,
  } = item;

  const handleClick = () => handleModal(id);

  return (
    <div className="project-card" data-project_id={id} onClick={handleClick}>
      <div
        className="project-card-image"
        style={{ backgroundImage: `url(${video_poster.url})` }}
      ></div>
      <div className="project-card-content">
        <h3 className="project-card-content__title">{title}</h3>
        {short_description && (
          <div
            className="project-card-content__description"
            dangerouslySetInnerHTML={{ __html: short_description }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default ProjectCard;
