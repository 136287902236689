import React, { useEffect } from "react";
import { gsap } from "gsap";
import { TweenMax, Linear } from "gsap/gsap-core";
import Particle1 from "../assets/particle-1.png";
import Particle2 from "../assets/particle-2.png";
import Particle3 from "../assets/particle-3.png";
import Particle4 from "../assets/particle-4.png";
import Particle5 from "../assets/particle-5.png";
import Particle6 from "../assets/particle-6.png";
const Particles = () => {
  useEffect(() => {
    gsap.registerPlugin(TweenMax);
    TweenMax.to(document.querySelector(".ani1img"), 20, {
      rotation: 360,
      top: 600,
      right: 40,
      scale: 2,
      repeat: -1,
      ease: Linear.easeNone,
      yoyo: true,
    });

    TweenMax.fromTo(
      document.querySelector(".ani2img"),
      20,
      {
        right: 0,
        scale: 0.5,
      },
      {
        rotation: 360,
        top: 600,
        scale: 1,
        repeat: -1,
        ease: Linear.easeNone,
        yoyo: true,
      }
    );

    TweenMax.fromTo(
      document.querySelector(".ani3img"),
      20,
      {
        left: 0,
        top: 500,
        scale: 0.5,
      },
      {
        rotation: 360,
        left: 600,
        scale: 1,
        repeat: -1,
        ease: Linear.easeNone,
        yoyo: true,
      }
    );

    TweenMax.fromTo(
      document.querySelector(".ani4img"),
      20,
      {
        right: 0,
        top: 900,
        scale: 0.5,
      },
      {
        rotation: 360,
        right: 600,
        top: 0,
        scale: 1,
        repeat: -1,
        ease: Linear.easeNone,
        yoyo: true,
      }
    );

    TweenMax.fromTo(
      document.querySelector(".ani5img"),
      20,
      {
        left: 0,
        top: 900,
        scale: 0.5,
      },
      {
        rotation: 360,
        left: 600,
        top: 0,
        scale: 1,
        repeat: -1,
        ease: Linear.easeNone,
        yoyo: true,
      }
    );

    TweenMax.fromTo(
      document.querySelector(".ani6img"),
      20,
      {
        right: 0,
        top: 500,
        scale: 0.5,
      },
      {
        rotation: 360,
        right: 600,
        top: 900,
        scale: 1,
        repeat: -1,
        ease: Linear.easeNone,
        yoyo: true,
      }
    );
  }, []);

  return (
    <>
      <div className="animatedparticles">
        <img src={Particle1} className="ani1img" alt="image1" />
        <img src={Particle2} className="ani2img" alt="image2" />
        <img src={Particle3} className="ani3img" alt="image3" />
        <img src={Particle4} className="ani4img" alt="image4" />
        <img src={Particle5} className="ani5img" alt="image5" />
        <img src={Particle6} className="ani6img" alt="image6" />
      </div>
    </>
  );
};

export default Particles;
