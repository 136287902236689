import React, { useState, useEffect } from "react";
import About from "../components/About";
import Hero from "../components/Hero";
import MyExperience from "../components/MyExperience";
import ProjectsFilter from "../components/ProjectsFilter";
import Preloader from "../components/Preloader";

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const preloader = document.querySelector("#preloader");
    if (preloader) {
      // Add the unmount class after a delay
      setTimeout(() => {
        preloader.classList.add("preloader--unmount");
      }, 1000);
    }

    // Set loading to false after a delay, and then remove the class from body
    const timerId = setTimeout(() => {
      setLoading(false);
      document.body.classList.remove("overflow-hidden");
    }, 1000);

    // Cleanup function to handle component unmount
    return () => {
      clearTimeout(timerId);
      if (preloader) {
        preloader.classList.remove("preloader--unmount");
      }
      document.body.classList.add("overflow-hidden");
    };
  }, []);

  return (
    <>
      {loading && <Preloader />}

      <Hero />
      <About />
      <MyExperience />
      <ProjectsFilter />
    </>
  );
};

export default Home;
