import React, { useState, useEffect } from "react";
import axios from "axios";
import ProjectCard from "./ProjectCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ProjectModal from "./ProjectModal";

library.add(faSpinner);

const apiURL = process.env.REACT_APP_API_URL;

const ProjectsFilter = () => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalData, setModalData] = useState({ show: false, data: null });
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const { data } = await axios.get(`${apiURL}projects`, {
          params: { filter_category: "all" },
        });
        setProjects(data.projects);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProjects();
  }, []);

  const handleModalOpen = async (projectId) => {
    try {
      const { data } = await axios.get(`${apiURL}projects/${projectId}`);
      setModalData({ show: true, data: data });
      document.body.style.overflow = "hidden";
    } catch (err) {
      console.error(err);
    }
  };

  const handleModalClose = () => {
    setModalData({ show: false, data: null });
    document.body.style.overflow = "auto";
  };

  return (
    <>
      <section id="projects">
        <div className={`projects-list ${isLoading ? "loading" : ""}`}>
          {isLoading ? (
            <FontAwesomeIcon icon="spinner" className="loading-icon" />
          ) : projects.length > 0 ? (
            projects.map((item) => (
              <div className="col" key={item.id}>
                <ProjectCard item={item} handleModal={handleModalOpen} />
              </div>
            ))
          ) : (
            <div className="no-projects">
              <h2>No projects found</h2>
            </div>
          )}
        </div>
      </section>
      {modalData.show && (
        <ProjectModal data={modalData.data} closeModal={handleModalClose} />
      )}
    </>
  );
};

export default ProjectsFilter;
