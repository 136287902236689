import React, { useState, useEffect } from "react";
import axios from "axios";
import SocialMedia from "./SocialMedia";
import { Link } from "react-router-dom";

const apiURL = process.env.REACT_APP_API_URL;
const home_url = process.env.REACT_APP_HOME_URL;

const Header = () => {
  const [headerData, setHeaderData] = useState({ menu_items: [] });
  const [headerClassName, setHeaderClassName] = useState("");
  const [mobMenuOpen, setMobMenuOpen] = useState(false);
  useEffect(() => {
    axios
      .get(`${apiURL}header`)
      .then((res) => {
        setHeaderData(res.data);
      })
      .catch((err) => {
        console.error("Failed to fetch header data:", err);
      });

    const handleScroll = () => {
      setHeaderClassName(window.scrollY > 0 ? "sticky" : "");
      handleActiveMenu();
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleActiveMenu = () => {
    const sections = document.querySelectorAll("section");
    let scrollY = window.pageYOffset;

    sections.forEach((current) => {
      const sectionHeight = current.offsetHeight;
      const sectionTop = current.offsetTop - 50; // Adjust based on header height or desired offset
      const sectionId = current.getAttribute("id");

      // Check if section is in view
      if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
        document.querySelectorAll(".main-menu a").forEach((item) => {
          item.classList.remove("active");
          if (item.getAttribute("href").endsWith(`#${sectionId}`)) {
            item.classList.add("active");
          }
        });
      }
    });
  };

  const scrollToSection = (event, url) => {
    event.preventDefault();
    const sectionId = url.split("#")[1];
    const section = document.getElementById(sectionId);

    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
      window.location.hash = sectionId; // Directly update the URL hash
    }
    setMobMenuOpen(false);
  };

  return (
    <header id="masthead" className={headerClassName}>
      <a href={home_url} className="logo-wrapper d-block">
        <img
          src={headerData.logo_url}
          alt={headerData.site_title}
          className="logo full-size-img full-size-img-contain"
        />
      </a>
      <ul className={`main-menu ${mobMenuOpen ? "active" : ""}`}>
        <button
          className="close-menu"
          onClick={() => setMobMenuOpen(false)}
          aria-label="Close mobile menu"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </button>

        {headerData.menu_items.map((item) => (
          <li key={item.id}>
            <Link
              to={item.url}
              className={item.classes}
              onClick={(event) => scrollToSection(event, item.url)}
            >
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
      <div className="social-media-wrapper">
        <SocialMedia />
      </div>
      <button
        className={`mobile-menu ${mobMenuOpen ? "active" : ""}`}
        onClick={() => setMobMenuOpen(!mobMenuOpen)}
        aria-label="Toggle mobile menu"
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
    </header>
  );
};

export default Header;
